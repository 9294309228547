import { DevelopmentConfig } from "./config.development"
import { ProductionConfig } from "./config.production"

const env = process.env
const isProd = env.NODE_ENV === "production"

// Use the appropriate configuration based on the environment
export const Config = isProd ? ProductionConfig : DevelopmentConfig

// Now, you can use Config to access your configuration settings
